function FlightVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var ticketTitle = '';
    var loginInformation = getStorage("loginInformation");
    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            method: "get",
            data: {},
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        var showTicketLink = `<a class="text-white" href="/flight-ticket?lang=${dataActive.location.query.lang}&referenceId=${data.bookingReferenceId}" target="_blank"> ${trans('Show_e_ticket')} </a>`;
        handler.domNode.find(".flight-summary-voucher-page").append(view("flight/flight-details-voucher-page", { data: data }));
        handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        let tripLeg = (data.serviceInfo.legs).length;
        let sectionColumn = '6';
        if (tripLeg == 3) {
            sectionColumn = '4';
        } else if (tripLeg == 4) {
            sectionColumn = '3';
        }
        if(data.queryParams.tripType == 'openReturn') {
            data.serviceInfo.legs[0].segments.forEach((item) => {
                handler.domNode.find(".trip-segments").append(view("flight/flight-segments-voucher-page", { item, sectionColumn }));
            });
        }
        else {
            data.serviceInfo.legs.forEach((leg) => {
                leg.segments.forEach(item => {
                    handler.domNode.find(".trip-segments").append(view("flight/flight-segments-voucher-page", { item, sectionColumn }));
                })
            });
        }
        data.booking_info.tickets.forEach((item, index) => {
            if (index == 0) {
                ticketTitle = convertPersionDateToEnglish(DateFormat((data.serviceInfo.legs[0].info.departure.raw_time || data.serviceInfo.legs[0].info.departure.rawTime), 'YMD')) + "-Flight-" + data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['first_name'][0]['caption']] + "-" + data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['last_name'][0]['caption']] + "-" + (!$.isEmptyObject(data.passsengersForm['all']['passport_number']) ? (data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['passport_number'][0]['caption']]) : (!$.isEmptyObject(data.passsengersForm['all']['nationalId']) ? data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['nationalId'][0]['caption']] : '')) + "-" + data.booking_info.pnr + "-" + (data.bookingReference.replace('#', ''));
            }
            handler.domNode.find(".passenger-information").append('<tr><td data-label="' + trans('No') + '">' + (index + 1) + '</td><td data-label="' + trans('Passenger') + '">' + (data.passengerInfo[item.passengerType][item.passengerIndex]['title'] || "") + " " + data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['first_name'][0]['caption']] + " " + data.passengerInfo[item.passengerType][item.passengerIndex][data.passsengersForm['all']['last_name'][0]['caption']] + '</td></tr>');

            if (data.bookingStatus == "waiting_issue") {
                handler.domNode.find('.refresh-voucher').removeClass('d-none');
            }
        })

        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>Pay</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "flight" });
        });

        handler.domNode.find('.print').on('click', function () {
            window.print()
        });

        if (data.bookingStatus == 'issued') {
            var domesticFlight = me.flightType(data);
            handler.domNode.find('.print-all-ticket').prop('disabled', false);
            handler.domNode.find('.download-all-tickets').prop('disabled', false);
            data.booking_info.tickets.forEach((passenger, index) => {
                passengerType = passenger.passengerType;
                switch (passenger.passengerType) {
                    case 'adults':
                        passengerType = 'adult';
                        break;
                    case 'children':
                        passengerType = 'child';
                        break;
                    case 'infants':
                        passengerType = 'infant';
                        break;
                    default:
                        break;
                }
                handler.domNode.find('.all-ticket').append(view('flight/passengers-tickets', { passenger: passenger, data: data, passengerType: passengerType, domesticFlight: domesticFlight, bookedBy: data.bookedBy }));
                if (!$.isEmptyObject(data.serviceInfo.fareRules)) {
                    handler.domNode.find('.flight-rules').empty();
                    data.serviceInfo.fareRules.forEach((ruleitem, ruleIndex) => {
                        handler.domNode.find('.flight-rules').append(view('flight/fare-rules-in-ticket', { ruleitem: ruleitem, domesticFlight: domesticFlight }))
                    });
                }
            });

            handler.domNode.find(".voucher-ticket-section").each((index, item) => {
                let $item = $(item);
                let passenger = $item.data('passenger');
                data.serviceInfo.legs.forEach((leg, legindex) => {
                    $item.append(view('flight/depart-ticket', { passenger, data, domesticFlight, leg }));
                    leg.segments.forEach((item, segmentIndex) => {
                        $($item.find(".flight-segments")[legindex]).append(view('flight/flight-segments-in-ticket', { item: item, data: data, domesticFlight: domesticFlight, passenger: passenger, segmentIndex: segmentIndex, leg: 0 }))
                    });
                });
            });
        }

        handler.domNode.find('.print-all-ticket').on('click', function () {
            handler.domNode.find('.voucher-content').addClass('voucher-content-not-printable');
            handler.domNode.find('.all-ticket').addClass('printable');
            document.title = ticketTitle.replace(/ /g, "-");
            window.print();
        });

        handler.domNode.find('.show-e-ticket').html(showTicketLink);

    }

    this.flightType = function (data) {
        handler.domNode.find('.all-ticket').addClass('en-ticket-in-other-lang');
        return false;
    }

    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}
