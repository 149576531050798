function PackageVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var passengerInfoSection = handler.domNode.find('.passenger-info-section .passenger-table-body');
    var leadPassenger = '';
    var lang = dataActive.location.query.lang;
    var loginInformation = getStorage("loginInformation");
    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find(".package-summary-voucher-page").append(view("package/voucher-page-package-title-section", { data: data, bookedBy: data.bookedBy }));
        handler.domNode.find(".ticket-header").append(view("partials/receipt-header", { bookedBy: data.bookedBy }));
        handler.domNode.find('.ticket-footer').append(view("partials/receipt-footer", { bookedBy: data.bookedBy, data }));
        var passengers = data.passengerInfo;
        var passengerForm = data.passsengersForm;
        let sellectedServices = data.serviceInfo.sellectedAvailability.sellectedServices || {};
        let services = data.serviceInfo.sellectedAvailability.services || {};
        var adultCount = 0;
        var childrenCount = 0;
        var childrenWOBedCount = 0;
        var infantCount = 0;
        var count = 0;
        var packagePassengers = data.booking_info.passengers;
        let primaryColor = getConfig('primary-color-voucher');
        let secondaryColor = getConfig('secondary-color-voucher');
        packagePassengers.forEach(passenger => {
            if (passenger.passengerType == 'adults') {
                adultCount += 1;
            }
            if (passenger.passengerType == 'children') {
                childrenCount += 1;
            }
            if (passenger.passengerType == 'childrenWOBed') {
                childrenWOBedCount += 1;
            }
            if (passenger.passengerType == 'infants') {
                infantCount += 1;
            }
        })
        for (var roomType in passengers) {
            count += 1;
            passengers[roomType].forEach((roommTypeItem, index) => {
                if (index == 0 && count == 1) {
                    leadPassenger = roommTypeItem['adults'][index][passengerForm['all']['first_name'][0]['caption']] + '-' + roommTypeItem['adults'][index][passengerForm['all']['last_name'][0]['caption']];
                }
                for (const passengerType in roommTypeItem) {
                    roommTypeItem[passengerType].forEach((passenger, passengerIndex) => {
                        packagePassengers.forEach((packagePassenger) => {
                            if(packagePassenger.passengerIndex == passengerIndex && packagePassenger.passengerType == passengerType && packagePassenger.roomType == roomType && packagePassenger.roomIndex == index) {
                                passengerInfoSection.append(view('package/passenger-info-in-voucher', { passenger: passenger, passengerForm: passengerForm, roomType: roomType }));
                            }
                        })
                    });
                }
            });

        }

        let from = dayjs(data.serviceInfo.sellectedAvailability.from);
        let to = dayjs(data.serviceInfo.sellectedAvailability.to);
        var tripLength = data.serviceInfo.sellectedAvailability.tripLength || ((to.diff(from, 'day')) + 1);
        handler.domNode.find(".package-info-section").append(view("package/package-info-section", { tripLength: tripLength, data }));
        handler.domNode.find(".package-booking-summary").append(view("package/info-in-voucher", { data: data, adultCount: adultCount, childrenCount: childrenCount, childrenWOBedCount: childrenWOBedCount, infantCount: infantCount }));
        handler.domNode.find('.print').on('click', function () {
            document.title = 'Package-Voucher-' + leadPassenger.replace(/ /g, "-");
            window.print();
        });

        handler.domNode.find('.download-voucher-ticket').on('click', function () {
            let exportName = 'Package-Voucher-' + leadPassenger.replace(/ /g, "-");
            ajax({
                url: dataActive.location.marketplace + "/v1/export/",
                method: "POST",
                data: {
                    service: 'voucherAndTicket',
                    exportName: exportName,
                    exportTemplate: 'fly4all-package-voucher',
                    exportFormat: 'pdf',
                    referenceId: data.bookingReferenceId,
                    lang: 'en',
                    color1: primaryColor,
                    color2: secondaryColor
                },
                success: function (data) {
                    if (data.status == true) {
                        window.open(data.openLink, '_blank')
                    }
                },
                error: function (data) {

                }
            });
        });

        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>" + trans('Pay') + "</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "package" });
        });
    }
    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}
