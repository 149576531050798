function FlightTicketProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var lang = dataActive.location.query.lang;
    var ticketTitle = '';
    var loginInformation = getStorage("loginInformation");

    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find('.flight-ticket-content-title').append(view("flight/flight-ticket-title", { data: data, lang: lang }));
        if (data.queryParams.return != null) {
            if (lang == 'AR' || lang == 'FA') {
                handler.domNode.find('.trip-to').append(' <i class="fa fa-solid fa-caret-right fs-20px"></i> ')
            } else {
                handler.domNode.find('.trip-to').prepend(' <i class="fa fa-solid fa-caret-right fs-20px"></i> ')
            }
        }
        handler.domNode.find('.flight-agancy-in-ticket').append(view("flight/flight-agancy", { data: data, bookedBy: data.bookedBy }));
        var tickets = data.booking_info.tickets;
        var passengerType = '';
        var domesticFlight = me.flightType(data);
        const $departTicket = view('flight/depart-ticket', { data: data, domesticFlight: domesticFlight });
        let adults = [];
        handler.domNode.find(".depart-ticket").append($departTicket);

        tickets.forEach((passenger, i) => {
            if (i == 0) {
                ticketTitle = convertPersionDateToEnglish(DateFormat((data.serviceInfo.legs[0].info.departure.raw_time || data.serviceInfo.legs[0].info.departure.rawTime), 'YMD') + "-Flight-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['first_name'][0]['caption']] + "-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['last_name'][0]['caption']] + "-" + (!$.isEmptyObject(data.passsengersForm['all']['passport_number']) ? (data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['passport_number'][0]['caption']]) : (!$.isEmptyObject(data.passsengersForm['all']['nationalId']) ? data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['nationalId'][0]['caption']] : '')) + "-" + data.booking_info.pnr + "-" + (data.bookingReference.replace('#', '')));
            }
            if (passenger.ticket_number[0][0] == decodeURIComponent(dataActive.location.query.ticketNumber)) {
                passengerType = passenger.passengerType;
                switch (passenger.passengerType) {
                    case 'adults':
                        passengerType = 'adult';
                        break;
                    case 'children':
                        passengerType = 'child';
                        break;
                    case 'infants':
                        passengerType = 'infant';
                        break;
                    default:
                        break;
                }
                ticketTitle = convertPersionDateToEnglish(DateFormat((data.serviceInfo.legs[0].info.departure.raw_time || data.serviceInfo.legs[0].info.departure.rawTime), 'YMD')) + "-Flight-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['first_name'][0]['caption']] + "-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['last_name'][0]['caption']] + "-" + (!$.isEmptyObject(data.passsengersForm['all']['passport_number']) ? (data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['passport_number'][0]['caption']]) : (!$.isEmptyObject(data.passsengersForm['all']['nationalId']) ? data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['nationalId'][0]['caption']] : '')) + "-" + data.booking_info.pnr + "-" + (data.bookingReference.replace('#', ''));
               
            }
            if (passenger.passengerType == 'adults') adults.push(passenger.passengerIndex);
        });
        if(data.queryParams.tripType == 'openReturn') {
            data.serviceInfo.legs[0].segments.forEach((item, segmentIndex) => {
                $(handler.domNode.find(".segments")).append(view('flight/flight-depart-segments', { segment: item, data: data, tickets: tickets, leg: 0, segmentIndex: segmentIndex, adults: adults, lang: lang }))
            });
        }
        else {
            data.serviceInfo.legs.forEach((leg, legindex) => {
                console.log(handler.domNode.find(".segments"));
                leg.segments.forEach((item, segmentIndex) => {
                    $(handler.domNode.find(".segments")).append(view('flight/flight-depart-segments', { segment: item, data: data, tickets: tickets, leg: legindex, segmentIndex: segmentIndex, adults: adults, lang: lang}))
                });
            });
        }
        // data.serviceInfo.legs.forEach((leg, legIndex) => {
        //     leg.segments.forEach((segment, segmentIndex) => {
        //         handler.domNode.find('.depart-ticket').find('.segments').append(view('flight/flight-depart-segments', { segment: segment, data: data, tickets: tickets, leg: legIndex, segmentIndex: segmentIndex, adults: adults, lang: lang }));
        //     })
        // });

        handler.domNode.find('.ticket-footer-content').append(` 
            <div class="fs-13">
                <h4 class="mb-0 fs-13 _mt-0"> ${!$.isEmptyObject(data.bookedBy) ? (data.bookedBy.searchTitle) : getConfig('title', '')} </h4>
                <p class="mb-0 fs-13"> ${(!$.isEmptyObject(data.bookedBy)) ? (data.bookedBy.mobile) : getConfig('mobile', '')} </p>
                <p class="mb-0 fs-13"> ${(!$.isEmptyObject(data.bookedBy)) ? (data.bookedBy.email) : getConfig('email', '')} </p>
                <p><strong>${trans('Created_by')}: </strong>${data.voucherBookedBy}</p>
            </div>`
        );


        handler.domNode.find('.print').on('click', function () {
            document.title = ticketTitle;
            window.print();
        });

    }

    this.flightType = function (data) {
        // this code for iran domesticFlight 
        // if (data.queryParams.origin.city.country_id == 105 && data.queryParams.destination.city.country_id == 105) {
        //     return true;
        // }
        handler.domNode.parent().addClass('en-ticket-in-other-lang');
        return false;
    }

    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}
